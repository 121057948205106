import axios from "axios";
import config from "../config.json";
import Utils from "../utils"; 

const api = axios.create({
  baseURL: Utils.isMobileDevice() ? config.API_MOBILE : config.API,
  headers: { 
      'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(config =>{
  const token = localStorage.getItem('token');
  config.headers.token = token ? token : false;
  return config;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && [401, 403].includes(error.response.status)) {
      localStorage.removeItem('dadosUser');
      localStorage.removeItem('token');
      return window.location.href = '/login';
    }
  }
);

export default api;