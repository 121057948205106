import Routers from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import { useState } from 'react';
import Load from './components/load';
import LoadContext from './context/loadContext';
import {PerfilProvider} from './context/perfilContext';

function App() {
  const [load, setLoad] = useState(false);
  return (
    <>
      <LoadContext.Provider value={{load, setLoad}}>
        <Load show={load}/>
          <PerfilProvider>
            <Routers/>
          </PerfilProvider>
        <ToastContainer/>
      </LoadContext.Provider>
    </> 
  );
}

export default App;
