import { useState, useEffect, useContext } from 'react';
import Lottie from 'lottie-react';
import { useNavigate, useLocation } from 'react-router-dom';
import {Row, Badge, ListGroup, ButtonGroup, Button} from 'react-bootstrap';
import Factory from '../../services/factory';
import { toast } from 'react-toastify';
import { ReactComponent as Concluido } from './../../images/concluido.svg';
import Description from '../../components/descricao';
import LoadContext from "../../context/loadContext";

import Library from "../../images/library.json";

import './style.scss';

const ListaAtividades = () => {
  const { setLoad } = useContext(LoadContext);

  const navigate = useNavigate();
  const [atividades, setAtividades] = useState([]);

  const location = useLocation();
  const { flg_concluidas } = location.state || {};

  const iniciarAtividade = (id, atividade) =>{
    atividade.EXERCICIOS = JSON.parse(atividade.EXERCICIOS);
    navigate(`/home/atividade/${id}`, { state: { atividade } })
  }

  const handlerFazer = () =>{
    navigate('/home/lista-atividades');
  }

  const handlerConcluidas = () =>{
    navigate('/home/atividades-concluidas', { state: { flg_concluidas: true } });
  }

  useEffect(()=>{
    const carregarAtividades = async () =>{
      setLoad(true)
      try {
        const resp = flg_concluidas ? await Factory.listarAtividadesConcluidas() : await Factory.listarAtividades();
        setLoad(false);
        setAtividades(resp.atividades);
      } catch (error) {
        setLoad(false);
        toast.error('Erro ao carregar atividades.');
      }
    }

    carregarAtividades();
  },[location, flg_concluidas, setLoad]);

  return(
    <>
      <Description str={flg_concluidas ? 'Concluidas' : 'A Fazer'}/>
      <Row>
        <ButtonGroup size="sm" className='filtro-exercicios'>
          <Button active={!flg_concluidas} onClick={handlerFazer}>A Fazer</Button>
          <Button active={flg_concluidas} onClick={handlerConcluidas} >Concluidos</Button>
        </ButtonGroup>
      </Row>
      
      <ListGroup as="ol" className={atividades.length > 0 ? 'lst-atividade' : ''}>
        {
          atividades.map((atv, key)=>(
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start cursor-pointer"
              key={`ATV-${key}`}
              onClick={()=> iniciarAtividade(atv.ID, atv)}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">{atv.TITULO} {flg_concluidas && (<Concluido className='badge-check'/>)}</div>
              </div>
              <Badge pill bg="secondary">
                DIFICULDADE: {atv.LEVEL}
              </Badge>
            </ListGroup.Item>
          ))
        }
        {
          (!atividades || atividades.length === 0) &&
          <div className='animation-not-atv'>
            <Lottie 
              animationData={Library} 
            />

            { flg_concluidas ? 'Atividades concluídas aparecerão aqui!': 'Atividades a fazer aparecerão aqui!' }
          </div>
        }
      </ListGroup>
    </>

  );
}

export default ListaAtividades;