import { Row, Col, Accordion, Badge, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ModalCentered from '../../components/modal-centered';

import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { javascript } from '@codemirror/lang-javascript';

import { faCheck, faXmark, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typewriter } from 'react-simple-typewriter';
import DOMPurify from 'dompurify';

import Lottie from 'lottie-react';
import Parabens from './../../images/parabens3.json';

import LoadContext from "../../context/loadContext";

import Factory from '../../services/factory';

import './style.scss';

const Atividade = () =>{
  const { setLoad } = useContext(LoadContext);
  const [code, setCode] = useState(`function init(param){\n\n}`);
  const [activeKey, setActiveKey] = useState("0");
  const [logTests, setLogTests] = useState([]);
  const [disabledSend, setDisabledSend] = useState(true);
  const [testConcluido, setTestConcluido] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [contentModal, setContentModal] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const { atividade } = location.state || {};

  const [currentTestIndex, setCurrentTestIndex] = useState(0);

  useEffect(() => {
    if (currentTestIndex < logTests.length) {
      const timeout = setTimeout(() => {
        setCurrentTestIndex(prevIndex => prevIndex + 1);
      }, currentTestIndex === 0 ? 100 : 1250);

      if(currentTestIndex === (logTests.length - 1) && testConcluido) {
        if(!atividade?.RESPOSTAS) setDisabledSend(false);
      };
      
      return () => clearTimeout(timeout);
    }
  }, [currentTestIndex, logTests, testConcluido, atividade]);

  const handlerTestar = async () =>{
    try {
      setLoad(true);
      setLogTests([]);
      setActiveKey("");
      setCurrentTestIndex(0);

      const params = {
        exercicio:{
          describe: atividade.EXERCICIOS.describe,
          function: atividade.EXERCICIOS.function,
          respostas: atividade.EXERCICIOS.respostas
        },
        code: code
      }
      const resp = await Factory.testarAtividades(params);

      setLoad(false);

      setActiveKey("1");
      setLogTests(resp?.tests);

      setTestConcluido(resp.success);

    } catch (error) {
      setLoad(false);
      console.log('Error =>',error)
    }
  }

  const handlerEnviar = async () =>{
    try {
      setLoad(true);
      const params ={
        id_atv: atividade.ID,
        resposta: code
      }

      await Factory.enviarAtividade(params);
      setLoad(false);
      setShowAnimation(true);
      toast.success('Parabens! Atividae concluida com sucesso!');
      setTimeout(()=> navigate('/home/atividades-concluidas', { state: { flg_concluidas: true } }), 3000);
    } catch (error) {
      setLoad(false);
      console.log('Error', error);
    }
  }

  const handleCodeChange = (newCode) => {
    setDisabledSend(true);
    setCode(newCode);
  };

  const restetAtv = () =>{
    try {
      const exer = atividade?.EXERCICIOS;
      let resp = exer.function.match(/\((.*?)\)/);

      setCode(`function ${exer.function}{\n   return ${exer.ret_function ? exer.ret_function : resp[1]}; \n} \n\n\n\n\n\n`);
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    if(atividade?.EXERCICIOS && !atividade?.RESPOSTAS){
      const exer = atividade?.EXERCICIOS;
      let resp = exer.function.match(/\((.*?)\)/);

      setCode(`function ${exer.function}{\n   return ${exer.ret_function ? exer.ret_function : resp[1]}; \n} \n\n\n\n\n\n`);
    }else{
      setCode(atividade?.RESPOSTAS);
    }
  },[atividade]);

  useEffect(()=>{
    const isNotificacao = async () =>{
      try {
        setLoad(true);
        const resp = await Factory.notificacao();
        setLoad(false);
        if(resp.modal){
          const user = JSON.parse(localStorage.getItem('dadosUser'));
          resp.modal.username = user.nome;
          setContentModal(resp.modal);
          setModalShow(true);
        }
      } catch (error) {
        setLoad(false);
        console.log(error);
      }
    }

    isNotificacao();
  },[setLoad]);

  return (
      <Row className='content-atividade'>
        <Row>
          <Col>
            <Badge pill bg="secondary">
              DIFICULDADE: {atividade?.LEVEL}
            </Badge>
            <h3>{atividade?.TITULO}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={5} className="col-atv">
            <Accordion activeKey={activeKey}>
              <Accordion.Item eventKey="0" onClick={()=>setActiveKey("0")}>
                <Accordion.Header>DESCRIÇÃO</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(atividade?.DESCRICAO || "") }}></div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" onClick={()=>setActiveKey("1")}>
                <Accordion.Header>Log Testes</Accordion.Header>
                <Accordion.Body>
                  <ul className='list-log-tests'>
                    {logTests.slice(0, currentTestIndex).map((test, index) => (
                      <li key={index} className={test.error ? 'error': 'success'}>
                        <FontAwesomeIcon icon={test.error ? faXmark : faCheck} />
                        <Typewriter
                          words={[` ${test.description} \n ${test.error ? test.error : ''}`]}
                          typeSpeed={5}
                          delaySpeed={0}
                          loop={1}
                        />
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col xs={12} sm={12} md={6} className="col-atv">
            <CodeMirror
              value={code}
              height="100%"
              theme={vscodeDark}
              extensions={[javascript({ jsx: true })]}
              basicSetup={{
                foldGutter: false,
                lineNumbers:false
              }}
              onChange={handleCodeChange}
            />
            <Col xs={12} sm={12} md={12} className="btns-send-atv">
              <Button variant="outline-primary" onClick={handlerEnviar} disabled={disabledSend}>Enviar</Button>
              <Button variant="outline-secondary" onClick={handlerTestar}>Testar</Button>
              <Button variant="outline-secondary" onClick={restetAtv}><FontAwesomeIcon icon={faRotateLeft}/> Reset  </Button>
            </Col>
          </Col>
        </Row>
        {
          showAnimation &&
          (
            <div className='animation-parabens'>
              <Lottie 
                animationData={Parabens} 
                style={{ width: '100%', height: '100%' }}  
              />
            </div>
           )
        }
        <ModalCentered 
          show={modalShow}
          {
            ...contentModal
          }
          onHide={() => setModalShow(false)}
        />
      </Row>
  );
}

export default Atividade;