import config from './../../config.json';
import { Outlet } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Navbar, Image, Dropdown } from 'react-bootstrap';
import OneSignal from 'react-onesignal';
import renewToken from './../../hooks/renewToken';
import { faChalkboardTeacher, faRightFromBracket, faUser, faUsers, faBook, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as LCODE } from './../../images/LCODE.svg';

import { PerfilContext } from "../../context/perfilContext";

import './style.scss';
import Factory from '../../services/factory';

const Template = () =>{
  const { perfil } = useContext(PerfilContext);
  const navigate = useNavigate();
  const location = useLocation();
  renewToken();

  const handlerFazer = () =>{
    navigate('/home/lista-atividades');
  }

  const handlerBiblioteca = () =>{
    navigate('/home/biblioteca');
  }

  const handleSala = () =>{
    navigate('/home/salas');
  }

  const handlerHome = () =>{
    navigate('/home');
  }

  const handlerLogout = () =>{
    localStorage.removeItem('dadosUser');
    localStorage.removeItem('token');
    return window.location.href = '/login';
  }

  useEffect(()=>{
    //iniciar notificações push
    const notification = async () => {
      try {
        await OneSignal.init({
          appId: config.FLG_LOCAL ? config.APP_ID_NOTIFICATION_LOCAL : config.APP_ID_NOTIFICATION,
          allowLocalhostAsSecureOrigin: config.FLG_LOCAL,
          promptOptions: {
            slidedown: {
              enabled: true,
              actionMessage: "Jovem gafanhota(o), aceite as notificações e SEJA FELIZ!",
              acceptButtonText: "SIIMM",
              cancelButtonText: "NÃO",   
            } 
          }
        });
    
        OneSignal.Slidedown.promptPush();

        if(OneSignal.User.PushSubscription.id) await Factory.inscricaoNotificacos({oneSignalId: OneSignal.User.PushSubscription.id})
        
      } catch (error) {
        console.error('Erro notificação =>',error);
      }
    };

    //validar se existe token
    const token = localStorage.getItem('token');

    if(!token) 
      navigate('/login');
    else
      notification();

  },[navigate]);

  const fotoPerfil = () =>{
    let dadosUser = localStorage.getItem('dadosUser');
    if(dadosUser) dadosUser = JSON.parse(dadosUser);
    return(
      <>
        {
          dadosUser && (
            <Dropdown>
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                <Image className="foto-perfil" src={dadosUser.foto_perfil} roundedCircle />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={()=>navigate('/home/perfil', {state: {flg_none_menu: true}})}>
                  {dadosUser.nome}
                  <FontAwesomeIcon icon={faUser}/>
                </Dropdown.Item>
                {
                  ['ADM'].includes(perfil) &&
                  (
                    <Dropdown.Item onClick={()=>navigate('/home/usuarios', {state: {flg_none_menu: true}})}>
                      Usuários
                      <FontAwesomeIcon icon={faUsers}/>
                    </Dropdown.Item>
                  )
                }
                <Dropdown.Divider />
                <Dropdown.Item onClick={handlerLogout}>
                  Logout
                  <FontAwesomeIcon icon={faRightFromBracket}/>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )
        }
      </>
    )
  }


  const isEditPerfil = () =>{
    try {
      const { flg_none_menu } = location.state;
      return flg_none_menu;
    } catch (error) {
      return false
    }
  }

  return(
    <Container fluid>
      <Row>
        <Navbar className="bg-body-tertiary l-code-navbar">
          <Navbar.Brand to="home">
              <LCODE className='logo-svg' onClick={handlerHome} />
              {fotoPerfil()}
          </Navbar.Brand>
        </Navbar>
      </Row>
      <Row className='l-code-content'>
        <Col
          xs={{span:12}}
          sm={{span:10, offset:1}}
          md={{span:10, offset:1}}
          className="window-content"
        >
          <Outlet/>
        </Col>
      </Row>
      {
        !isEditPerfil() &&
        (
          <Row className='justify-content-md-center div-centralizada  menu'>
            <Col xs={4} sm={4} md={4} onClick={handlerFazer}> 
              <FontAwesomeIcon icon={faDumbbell}/>  <br/> Exercicios
            </Col>
            <Col xs={4} sm={4} md={4} onClick={handleSala} >  
              <FontAwesomeIcon icon={faChalkboardTeacher}/>  <br/> Sala
            </Col>
            <Col xs={4} sm={4} md={4} onClick={handlerBiblioteca}>  
              <FontAwesomeIcon icon={faBook}/>  <br/> Biblioteca 
            </Col>
          </Row>
        )
      }
    </Container>
  )
}
export default Template