import { createContext, useState, useEffect } from "react";

const PerfilContext = createContext();

const PerfilProvider = ({ children }) => {
  const [perfil, setPerfil] = useState(null);

  useEffect(() => {
    const dadosUser = localStorage.getItem('dadosUser');
    if (dadosUser) {
        const dadosJson = JSON.parse(dadosUser)
      setPerfil(dadosJson.perfil);
    }
  }, []);

  return (
    <PerfilContext.Provider value={{ perfil, setPerfil }}>
      {children}
    </PerfilContext.Provider>
  );
};

export { PerfilContext, PerfilProvider };
