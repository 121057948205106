import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import { Typewriter } from 'react-simple-typewriter';

import './style.scss';

function ModalCentered(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FontAwesomeIcon icon={faTerminal}/> {props.TITULO}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='diretorio'>
          <b>LCODE@TREINAMENTO:</b><b>/Users/{props.username}/atividades$</b>
        </p>
        <p>
        <Typewriter
            words={[props.TEXTO]}
            typeSpeed={4}
            delaySpeed={0}
            loop={1}
          />
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default ModalCentered;