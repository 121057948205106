import { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Factory from './../../services/factory';
import { toast } from 'react-toastify';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import LoadContext from "../../context/loadContext";
import { PerfilContext } from "../../context/perfilContext";

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

const Login = () =>{
  const { setLoad } = useContext(LoadContext);
  const { setPerfil } = useContext(PerfilContext);

  const navigate = useNavigate();
  const [formLogin, setFormLogin] = useState({});
  const [invalidInputs, setInvalidInputs] = useState({});
  const [viewPassword, setViewPassword] = useState(false);

  const handlerInput = (e) => {
    const {name, value} = e.target;
    setFormLogin({...formLogin, [name]: value });
  }

  const handleKeyPress = async(e) => {
    if (e.key === 'Enter') await login()
  };


  const login = async () =>{
    try {
      setInvalidInputs({});
      
      // validar inpurs 
      let inputs = {}
      if(!formLogin?.username || formLogin?.username === "") inputs = { usernameRequired: true};
      if(!formLogin?.password || formLogin?.password === "") inputs = { ...inputs, passwordRequired: true};

      if(inputs.usernameRequired || inputs.passwordRequired) return setInvalidInputs(inputs);

      setLoad(true);

      const resp = await Factory.login(formLogin);
      setLoad(false);
      if(resp.success){
        localStorage.setItem('token', resp.token);

        delete resp.token;
        setPerfil(resp.perfil);
        localStorage.setItem('dadosUser', JSON.stringify(resp));
        navigate('/home');
      }else{
        if(resp.cod) setInvalidInputs({[resp.cod]: true});
      }
      
    } catch (error) {
      setLoad(false);
      toast.error('Erro ao efetuar login, tente novamente mais tarde.')
    }
  }

  useEffect(()=>{
    const isToken = localStorage.getItem('token');
    if(isToken) navigate('/home');
  },[navigate])

  return(
    <Container className="container-login" fluid>
      <div className="triangle-container">
        <div className="logo">
          <div className="shadow-logo"></div>
          <h3>{ '</LCODE>' } </h3>
        </div>

        <Row>
          <Col xs={{ span: 10, offset: 1 }} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }} className="login-form">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Usuário</Form.Label>
                <Form.Control 
                  name="username" 
                  value={formLogin?.username || ''} 
                  type="text" 
                  onChange={e => handlerInput(e)}
                  placeholder="Digite seu username" 
                />
                {(invalidInputs.username || invalidInputs.usernameRequired) && 
                (
                  <Form.Text className="text-muted text-red">
                    { invalidInputs.usernameRequired ? 'Campo obrigatório' : 'Usuário não encontrado'}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group className="mb-3 login-password" controlId="formBasicPassword">
                <Form.Label>Senha</Form.Label>
                <Form.Control 
                  name="password" 
                  value={formLogin?.password || ''} 
                  type={viewPassword ? "text" : "password"} 
                  placeholder="Password" 
                  onChange={e => handlerInput(e)}
                  onKeyDown={handleKeyPress}
                />

                {!viewPassword && <FontAwesomeIcon icon={faEye} onClick={()=> setViewPassword(true)}/>}
                {viewPassword && <FontAwesomeIcon icon={faEyeSlash} onClick={()=> setViewPassword(false)}/>}

                {(invalidInputs.password || invalidInputs.passwordRequired) && 
                (
                  <Form.Text className="text-muted text-red">
                    {invalidInputs.passwordRequired ? 'Campo obrigatório' : 'Senha incorreta'}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Lembrar" />
              </Form.Group>
              <Button variant="primary" onClick={() => { login() } }>
                ACESSAR
              </Button>
            </Form>
          </Col>
      </Row>
      </div>
    </Container>
  );
}

export default Login;