import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login';
import Template from './pages/template';
import ListaAtividades from './pages/lista-atividas';
import Atividade from './pages/atividade';

// Lazy load components
const Perfil = lazy(() => import('./pages/perfil'));
const Biblioteca = lazy(() => import('./pages/biblioteca'));
const Salas = lazy(() => import('./pages/sala'));
const Users = lazy(() => import('./pages/users'));
const ViewSala = lazy(() => import('./pages/view-sala'));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Template />}>
            <Route index element={<ListaAtividades />} />
            <Route path="lista-atividades" element={<ListaAtividades />} />
            <Route path="atividades-concluidas" element={<ListaAtividades />} />
            <Route path="atividade/:id" element={<Atividade />} />
            <Route path="biblioteca" element={<Biblioteca />} />
            <Route path="perfil" element={<Perfil />} />
            <Route path="salas" element={<Salas />} />
            <Route path="sala/:cod" element={<ViewSala />} />
            <Route path="usuarios" element={<Users />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;