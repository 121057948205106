import API from "./api";

class Factory {
  //Users

  static async login(params) {
    const resp = await API.post('/api/usuarios/authenticate', params);
    return resp.data;
  }

  static async minhaConta() {
    const resp = await API.get('/api/usuarios/minha-conta');
    return resp.data;
  }

  static async renovarToken() {
    const resp = await API.get('/api/usuarios/renovar-token');
    return resp.data;
  }

  static async verificarUsername(params) {
    const resp = await API.post('/api/usuarios/verificar-username', params);
    return resp.data;
  }

  static async alterarUsuario(params) {
    const resp = await API.post('/api/usuarios/alterar', params);
    return resp.data;
  }
  static async listarUsuario() {
    const resp = await API.get('/api/usuarios/listar');
    return resp.data;
  }

  //Atividades
  static async listarAtividades() {
    const resp = await API.get('/api/atividades/lista');
    return resp.data;
  }

  static async listarAtividadesConcluidas() {
    const resp = await API.get('/api/atividades/lista-concluidas');
    return resp.data;
  }

  static async testarAtividades(params) {
    const resp = await API.post('/api/atividades/testar', params);
    return resp.data;
  }

  static async enviarAtividade(params) {
    const resp = await API.post('/api/atividades/enviar', params);
    return resp.data;
  }

  //Notificações

  static async notificacao() {
    const resp = await API.get('/api/atividades/notificacao');
    return resp.data;
  }

  static async inscricaoNotificacos(params) {
    const resp = await API.post('/api/notificacaos/inscricao', params);
    return resp.data;
  }

  //livros
  static async buscarLivros(params) {
    const resp = await API.post('/api/livros/listar', params);
    return resp.data;
  }

  static async buscarPDF(params) {
    const resp = await API.post('/api/livros/buscar-pdf', params,{
      responseType: 'arraybuffer'
    });
    return resp.data;
  }

  static async sala(params) {
    const resp = await API.post('/api/sala/criar', params);
    return resp.data;
  }

  static async listaTurmas() {
    const resp = await API.get('/api/sala/lista');
    return resp.data;
  }

  static async getIntegrantes(cod) {
    const resp = await API.post('/api/sala/integrantes', {id_turma: cod});
    return resp.data;
  }

  static async buscarAlunos(params) {
    const resp = await API.post('/api/sala/buscar-alunos', params);
    return resp.data;
  }

  static async adicionarAlunos(params) {
    const resp = await API.post('/api/sala/adicionar-alunos', params);
    return resp.data;
  }

  static async getInfoSala(param) {
    const resp = await API.get(`/api/sala/info/${param}`);
    return resp.data;
  }

  static async adicionarTemas(params) {
    const resp = await API.post('/api/sala/adicionar-tema', params);
    return resp.data;
  }

  static async adicionarAula(params) {
    const resp = await API.post('/api/sala/adicionar-aula', params);
    return resp.data;
  }

  static async verAula(params) {
    const resp = await API.post('/api/sala/ver-aula', params);
    return resp.data;
  }

}

export default Factory;