import './style.scss';
import {Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Description = props => {
  return(
    <Row className={`description ${props.nClass ? props.nClass : ''}`}>
      <Col md={11}>
        <div className='title'>
          <span className={props.size}>
            {
              props.icon && (
                <FontAwesomeIcon icon={props.icon}/>
              )
            }
            {props.str}
          </span>
        </div>
      </Col>
    </Row>
  )
}

export default Description;