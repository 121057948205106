import Lottie from 'lottie-react';
import animationLoad from '../../images/load.json'
import './style.scss';

const Load = (props) =>{

  return(
    <>
      {
        props.show && 
        <div className="load">
          <Lottie 
            animationData={animationLoad} 
          />
        </div>
      }
    </>
  )
}

export default Load;